import '../../../sass/apps/cookie_consent/banner.scss';
import cookies from 'js-cookie';
import consent from './consent';
import trackEvent from '../../helpers/gtm/eventPush';

let is_dialog_element_supported = true;

function openDialog(dialogElement) {
  if (is_dialog_element_supported) {
    dialogElement.showModal();
  } else {
    dialogElement.classList.add('dialog_polyfill--open');
  }
}

function closeDialog(dialogElement) {
  if (is_dialog_element_supported) {
    dialogElement.close();
  } else {
    dialogElement.classList.remove('dialog_polyfill--open');
  }
}

function drop_consent_cookie() {
  cookies.set('cookie_consent_set', 1);
}

function basic_dialog_polyfill() {
  const dialogs = document.querySelectorAll('dialog');
  dialogs.forEach((dialog) => {
    dialog.classList.add('dialog_polyfill');
    if (dialog.hasAttribute('open')) {
      dialog.classList.add('dialog_polyfill--open');
    }
    const close_form = dialog.querySelector('form[method="dialog"]');
    if (close_form) {
      close_form.addEventListener('submit', (event) => {
        event.preventDefault();
        dialog.classList.remove('dialog_polyfill--open');
      });
    }
  });
}

function check_dialog_element_support() {
  if (typeof HTMLDialogElement === 'function') {
    return true;
  }
  return false;
}

function close_banner_if_consent_already_given({ banner }) {
  if (consent.stored_consent_is_not_default()) {
    closeDialog(banner);
    drop_consent_cookie();
  }
}

function configure_preference_centre({
  opt_out,
  preferences,
  cookie_consent_set,
}) {
  if (!cookie_consent_set && opt_out) {
    preferences.forEach((input) => {
      input.checked = true;
    });
  } else {
    const {
      ad_storage,
      ad_user_data,
      ad_personalization,
      analytics_storage,
      functionality_storage,
      personalization_storage,
      security_storage,
    } = consent.get_stored_consent();
    if (
      ad_storage == 'granted' &&
      ad_user_data == 'granted' &&
      ad_personalization == 'granted'
    ) {
      preferences.forEach((input) => {
        if (input.name == 'targeting') {
          input.checked = true;
        }
      });
    }
    if (analytics_storage == 'granted' && security_storage == 'granted') {
      preferences.forEach((input) => {
        if (input.name == 'performance') {
          input.checked = true;
        }
      });
    }
  }
}

function add_event_listener_to_preference_centre({
  preferences,
  cookie_preference_form,
  preference_centre,
  banner,
}) {
  cookie_preference_form.addEventListener('submit', (event) => {
    event.preventDefault();
    const selected_conset = {};
    preferences.forEach((input) => {
      selected_conset[input.name] = !!input.checked;
    });
    consent.update_consent(selected_conset);
    drop_consent_cookie();
    if (banner) {
      closeDialog(banner);
    }
    trackEvent({
      category: 'banners',
      action: 'cookie_privacy_pref_centre_confirm_choices',
      label: `targeting:${selected_conset.targeting ? 1 : 0}|functional:${
        selected_conset.functional ? 1 : 0
      }|performance:${selected_conset.performance ? 1 : 0}`,
    });
    closeDialog(preference_centre);
  });
}

function init({ opt_out = false } = {}) {
  consent.enable_gtag();
  is_dialog_element_supported = check_dialog_element_support();
  if (!is_dialog_element_supported) {
    basic_dialog_polyfill();
  }
  const banner = document.getElementById('cookie-consent-banner');
  const cookie_consent_set = cookies.get('cookie_consent_set');
  /* 👇 temporary check to prevent active users having to reconsent whilst we launch new banner.
    We should delete this after a couple of weeks */
  if (!cookie_consent_set && banner) {
    close_banner_if_consent_already_given({ banner });
  }
  if (banner) {
    const preference_centre_link = banner.querySelector(
      '#cookie-preference-center-link',
    );
    const accept_all_button = banner.querySelector(
      '#accept-all-cookies-button',
    );

    preference_centre_link.addEventListener('click', () => {
      configure_preference_centre({ opt_out, preferences, cookie_consent_set });
      openDialog(preference_centre);
      trackEvent({
        category: 'banners',
        action: 'cookie_consent_cookie_settings',
      });
    });
    accept_all_button.addEventListener('click', () => {
      consent.accept_all();
      trackEvent({
        category: 'banners',
        action: 'cookie_consent_accept_all',
        label: 'targeting:1|functional:1|performance:1',
      });
      drop_consent_cookie();
      closeDialog(banner);
    });
    const close_banner_button = banner.querySelector('form[method="dialog"]');
    close_banner_button.addEventListener('click', () => {
      trackEvent({
        category: 'banners',
        action: 'cookie_consent_close',
      });
    });
  }

  const preference_centre = document.getElementById('cookie-preference-center');
  const footer_link = document.getElementById('footer-cookie-settings-link');
  const preference_centre_allow_all = preference_centre.querySelector(
    '#consent-preference-form-allow-all',
  );

  if (preference_centre_allow_all) {
    preference_centre_allow_all.addEventListener('click', () => {
      consent.accept_all();
      trackEvent({
        category: 'banners',
        action: 'cookie_privacy_pref_centre_accept_all',
        label: 'targeting:1|functional:1|performance:1',
      });
      drop_consent_cookie();
      if (banner) {
        closeDialog(banner);
      }
      closeDialog(preference_centre);
    });
  }

  if (footer_link) {
    footer_link.addEventListener('click', () => {
      configure_preference_centre({
        opt_out,
        preferences,
        cookie_consent_set: cookies.get('cookie_consent_set'),
      });
      trackEvent({
        category: 'banners',
        action: 'cookie_settings',
        label: window.location.href.split('?')[0],
      });
      openDialog(preference_centre);
    });
  }
  const cookie_preference_form = preference_centre.querySelector(
    '#consent-preference-form',
  );
  const preferences = cookie_preference_form.querySelectorAll(
    'input[type="checkbox"]',
  );
  configure_preference_centre({
    opt_out,
    preferences,
    cookie_consent_set: cookies.get('cookie_consent_set'),
  });
  add_event_listener_to_preference_centre({
    preferences,
    cookie_preference_form,
    preference_centre,
    banner,
  });

  const close_preference_centre_button = preference_centre.querySelector(
    'form[method="dialog"]',
  );
  close_preference_centre_button.addEventListener('click', () => {
    trackEvent({
      category: 'banners',
      action: 'cookie_privacy_pref_centre_close',
    });
  });
}

export default { init };
